
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <DataTable
        :value="customers"
        :lazy="true"
        :paginator="true"
        class="p-datatable-customers"
        :rows="per_page"
        :totalRecords="totalRows"
        @page="onPage($event)"
        @sort="onSort($event)"
        dataKey="id"
        :rowHover="true"
        :filters="filters"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">Deposit List</div>
        </template>
        <template #empty> No record found. </template>
        <template #loading> Loading data. Please wait. </template>

        <Column header="Name" :sortable="true" sortField="name">
          <template #body="slotProps">
            <span class="p-column-title">Name</span>
            {{ slotProps.data.user.name }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.name"
              @keydown.enter="topFilter(filters.name, 'name')"
              class="p-column-filter"
              placeholder="Search by Name"
            />
          </template>
        </Column>

        <Column header="Email" :sortable="true" sortField="email">
          <template #body="slotProps">
            <span class="p-column-title">Email</span>
            <span
              class="text-truncate d-inline-block"
              style="max-width: 100px"
              :title="slotProps.data.user.email"
              >{{ slotProps.data.user.email }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.email"
              @keydown.enter="topFilter(filters.email, 'email')"
              class="p-column-filter"
              placeholder="Search by Email"
            />
          </template>
        </Column>

        <Column header="Wallet Address" :sortable="true" sortField="email">
          <template #body="slotProps">
            <span class="p-column-title">Wallet Address"</span>
            <span
              class="text-truncate d-inline-block"
              style="max-width: 150px"
              :title="slotProps.data.user_wallet_address"
            >
              {{ slotProps.data.user_wallet_address }}</span
            >
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.user_wallet_address"
              @keydown.enter="
                topFilter(filters.user_wallet_address, 'user_wallet_address')
              "
              class="p-column-filter"
              placeholder="Search by Wallet"
            />
          </template>
        </Column>

        <Column header="Symbol" :sortable="true" sortField="symbol">
          <template #body="slotProps">
            <span class="p-column-title">Symbol</span>
            {{ slotProps.data.symbol }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.symbol"
              @keydown.enter="topFilter(filters.symbol, 'symbol')"
              class="p-column-filter"
              placeholder="Search by Symbol"
            />
          </template>
        </Column>

        <Column header="Token Type" :sortable="true" sortField="token_type">
          <template #body="slotProps">
            <span class="p-column-title">Token Type</span>
            {{ slotProps.data.token_type }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.token_type"
              @keydown.enter="topFilter(filters.token_type, 'token_type')"
              class="p-column-filter"
              placeholder="Search by Token Type"
            />
          </template>
        </Column>

        <Column header="Amount" :sortable="true" sortField="amount">
          <template #body="slotProps">
            <span class="p-column-title">Amount</span>
            {{ slotProps.data.amount }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters.amount"
              @keydown.enter="topFilter(filters.amount, 'amount')"
              class="p-column-filter"
              placeholder="Search by Amount"
            />
          </template>
        </Column>

        <!-- <Column
          header="Token Address"
          sortField="token_address"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">Token Address</span>
            {{ slotProps.data.token_address }}
          </template>
          >
        </Column> -->

        <Column header="Status" sortField="status" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Status</span>
            {{ slotProps.data.status }}
          </template>
          <template #filter>
            <Dropdown
              v-model="filters.status"
              @change="topFilter($event.value, 'status')"
              :options="statuses"
              placeholder="Select a Status"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <span :class="'customer-badge status-' + slotProps.option">{{
                  slotProps.option
                }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          header="Created At"
          sortField="created_at"
          :sortable="true"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Created At</span>
            {{
              new Date(slotProps.data.created_at).toLocaleDateString("en-US")
            }}
          </template>
          <template #filter>
            <Calendar
              v-model="filters.date"
              :showButtonBar="true"
              dateFormat="yy-mm-dd"
              class="p-column-filter"
              @date-select="topFilter(filters.date, 'date')"
              placeholder="Created Date"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import ApiClass from "../../../api/api";

import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";

export default {
  components: {
    Layout,
    PageHeader,
    DataTable,
    Column,
    InputText,
    Dropdown,
    Calendar,
  },
  data() {
    return {
      totalRows: 1,
      per_page: 10,
      page: 1,
      title: "Deposit Report",
      items: [
        {
          text: "Deposit",
          href: "#",
        },
        {
          text: "Report",
          active: true,
        },
      ],
      filters: {
        name: null,
        email: null,
        user_wallet_address: null,
        symbol: null,
        token_type: null,
        amount: null,
        status: null,
        date: null,
      },
      statuses: ["completed", "pending"],
      filter: "",
      customers: [],
      loading: true,
    };
  },

  mounted() {
    this.getDeposit();
  },
  methods: {
    async getDeposit() {
      await ApiClass.getRequest(
        "admin/report/deposit?per_page=" +
          this.per_page +
          "&page=" +
          this.page +
          "&" +
          this.filter
      ).then((res) => {
        // console.log("data===", res);
        if (res.data.status_code == 1) {
          this.loading = false;
          this.customers = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.totalRows = res.data.data.total;
        }
      });
    },
    onPage(event) {
      if (this.page != event.page + 1) {
        this.loading = !this.loading;
        this.page = event.page + 1;
      }
      if (this.per_page != event.rows) {
        this.loading = !this.loading;
        this.per_page = event.rows;
      }
      this.getDeposit();
    },
    onSort(e) {
      this.loading = !this.loading;
      var sortby = e.sortOrder == 1 ? "asc" : "desc";
      this.filter = "sortby=" + sortby + "&sortbyname=" + e.sortField;
      this.getDeposit();
    },
    topFilter(columnVal, columnName) {
      if (columnName == "date") {
        columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
        columnVal = columnVal.split("/").reverse().join("-");
      }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;

      this.getDeposit();
    },
  },
};
</script>
  <style lang="scss">
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-calendar .p-datepicker {
  width: 350px;
}
.p-inputtext {
  font-size: 0.8rem;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>